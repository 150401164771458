import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import { useSiteContext } from "../contexts/site"

function SymptomsCard({ rounded = true }) {
  const { locale } = useSiteContext()

  const query = graphql`
    query AllConditions {
      firestore {
        en: conditions(locale: "en") {
          id
          title
          href
          description
          thumbnail
        }
        ch: conditions(locale: "ch") {
          id
          title
          href
          description
          thumbnail
        }
      }
    }
  `

  return (
    <StaticQuery
      query={query}
      render={data => (
        <div className={`${rounded?'rounded-lg':''} bg-brick text-white p-5 lg:p-8 shadow-md w-11/12 lg:w-[420px]`}>
          <h3 className="text-lg lg:text-2xl flex items-center">
            <svg className="w-6 lg:w-8 inline" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor" d="M96 480h320V128h-32V80c0-26.51-21.49-48-48-48H176c-26.51 0-48 21.49-48 48v48H96v352zm96-384h128v32H192V96zm320 80v256c0 26.51-21.49 48-48 48h-16V128h16c26.51 0 48 21.49 48 48zM64 480H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v352zm288-208v32c0 8.837-7.163 16-16 16h-48v48c0 8.837-7.163 16-16 16h-32c-8.837 0-16-7.163-16-16v-48h-48c-8.837 0-16-7.163-16-16v-32c0-8.837 7.163-16 16-16h48v-48c0-8.837 7.163-16 16-16h32c8.837 0 16 7.163 16 16v48h48c8.837 0 16 7.163 16 16z" ></path>
            </svg>
            <span className="ml-2 font-light">{locale==='ch'?'常見痛症':'Commonly Treated Conditions'}</span>
          </h3>
          <ul className="grid grid-cols-2 mt-6">
            {
              data.firestore[locale].map(item => (
                <li key={item.id} className="flex items-center my-1 pb-2 space-x-1 border-b border-gray-400">
                  <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <Link className="text-sm lg:text-base" to={item.href}>{item.title}</Link>
                </li>
              ))
            }
          </ul>
        </div>
      )}
    />
  )
}

export default SymptomsCard