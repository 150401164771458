import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { useSiteContext } from "../contexts/site"

function FAQCard({ rounded = true, items }) {
  const [revealAnswer, setRevealAnswer] = useState(false)
  const { locale } = useSiteContext();
  const index = Math.floor(Math.random() * 12);
  const query = graphql`
    query FAQs {
      firestore {
        en: faqs(locale: "en") {
          id
          question
          answer
        }
        ch: faqs(locale: "ch") {
          id
          question
          answer
        }
      }
    }
  `

  return (
    <StaticQuery
      query={query}
      render={data => (
        <div className={`${rounded ? 'rounded-lg' : 'rounded-r-lg'} bg-coconut text-white p-5 lg:p-8 shadow-md w-11/12 lg:w-[420px]`}>
          <h3 className="text-lg lg:text-2xl flex items-center">
            <svg className="w-5 h-5 lg:w-6 lg:h-6 inline" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor" d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
            </svg>
            <span className="ml-2 font-light">{locale === 'ch' ? '常見問題' : 'Frequently Asked Questions'}</span>
          </h3>
          <dl className="flex my-12">
            <dt>
              <div className="h-8 w-8 bg-mango text-white rounded-full flex justify-center items-center">Q</div>
            </dt>
            <dd onClick={() => setRevealAnswer(!revealAnswer)} className="ml-4 text-base lg:text-xl cursor-pointer">
              {data.firestore[locale][index]["question"]}
              {revealAnswer && (
                <p className="mt-8" dangerouslySetInnerHTML={{ __html: data.firestore[locale][index]["answer"] }}></p>
              )}
            </dd>
          </dl>
        </div>
      )}
    />
  )
}

export default React.memo(FAQCard)