import React from "react";
// import { StaticImage } from "gatsby-plugin-image";

function PageHeader({ title, imageUrl }) {
  return (
    <section className="w-screen flex items-center lg:h-88 md:h-[480px] sm:h-[480px] relative">
      <h1 className="mx-6 text-white text-4xl md:text-6xl filter drop-shadow-md z-20 my-24 lg:my-16 xl:mx-36">{title}</h1>
      <img
        className="absolute w-screen top-0 bottom-0 h-full backdrop-filter backdrop-brightness-50 object-cover opacity-80"
        src={imageUrl}
        alt={title}
      />
    </section>
  )
}

export default PageHeader;