import { Link } from "gatsby"
import React from "react"
import { useSiteContext } from "../contexts/site"

function AdmissionGreetingCard({ rounded = true }) {

  const { locale } = useSiteContext();
  const translations = {
    en: {
      title: 'New Patients',
      cta: `Are you thinking of getting chiropractic treatment in Hong Kong and wondering what to expect?`,
      admissionButtonText: 'Your First Visit',
      feeAndInsuranceButtonText: 'Fees & Coverage',
    },
    ch: {
      title: '首次會診',
      cta: `你也想體驗一下脊科治療嗎?`,
      admissionButtonText: '首次會診',
      feeAndInsuranceButtonText: '收費及醫療保險',
    }
  }

  return (
    <div className={`${rounded ? 'rounded-lg' : 'rounded-l-lg'} bg-mango text-white p-5 lg:p-8 shadow-md w-11/12 lg:w-[420px]`}>
      <h3 className="text-lg lg:text-2xl flex items-center">
        <svg className="w-6 lg:w-8 inline" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
          <path fill="currentColor" d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
        </svg>
        <span className="ml-2 font-light">{translations[locale]['title']}</span>
      </h3>
      <p className="my-6 text-base lg:text-lg">{translations[locale]['cta']}</p>
      <div className="flex justify-around items-start text-sm lg:items-evenly lg:space-y-0 lg:text-base">
        <Link to={`/${locale}/what-to-expect/first-visit`} className="bg-white rounded-md text-mango py-3 px-4 font-semibold">
          {translations[locale]['admissionButtonText']}
        </Link>
        <Link to={`/${locale}/what-to-expect/fees`} className="bg-white rounded-md text-mango py-3 px-4 font-semibold">
          {translations[locale]['feeAndInsuranceButtonText']}
        </Link>
      </div>
    </div>
  )
}

export default AdmissionGreetingCard